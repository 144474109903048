/* WHOLE PAGE */
.body-home {
  background-color: #FFC0CB;
  color: white;
  font-family: 'Martel', serif;
  overflow-y: scroll;
}

.padding-sides-10 {
  padding-left: 10%;
  padding-right: 10%;
}

.margin-sides-10 {
  margin-left: 10%;
  margin-right: 10%;
}
.full-height {
  height: 100vh;
}

.home-h1 {
  font-family: 'Prompt', sans-serif;
  font-size: 5vh;
}

a {
  background-color: #347FC4;
  color: white;
  text-decoration: none;
  padding: 3px;
  animation: link-out 0.5s forwards;
}

a:hover {
  animation: link 0.5s forwards;
  text-decoration: dashed;
  color: #272838;
}

@keyframes link {
  0% {
    color: white;
  }
  100% {
    color: black;
  }
}

@keyframes link-out {
  0% {
    color: black;
  }
  100% {
    color: white;
  }
}

/* SECTION INTRO */

.intro{
  display: flex;
  flex-direction: row;
  height: 55vh;
  padding: 10%;
  justify-content: center;
  color: #272838
}

.intro-left{
  position: relative;
  order: 2;
  width: 40%;
}

.intro-right{
  order: 1;
  height: 50%;
}

.short-description-text {
  font-size: 6vh;
  opacity: 0;
  display: inline-block;
}

.short-description-wrapper{
  width: 90%;
  display: block;
  position: relative;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.pop-from-left{
  font-family: 'Prompt', sans-serif;
  z-index: 1;
  overflow: hidden;
  animation: 1s pop-from-left;
  margin-top: 0%;
}

@keyframes pop-from-left {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}

.intro video {
  width: 100% !important;
  height: 100% !important;
  max-width: 300px;
  min-width: 250px;
  min-height: 100px;
}

.danilo-video{
  position: absolute;
  transform: translate(0,0) scale(0);
  transition-duration: 0.1s;
  max-width: 100%;
  max-height: 100%;
  bottom: 0;
  right: 0;
}

.arrow-down{
  text-align: center;
  font-size: 4vh;
  opacity: 0;
  color: #272838
}

@media only screen and (max-width: 992px){
  .intro {
    flex-direction: column;
    justify-content: center;
    justify-content: space-around;
    height: 80vh;
  }

  .intro-left{
    width: 100%;
  }
  
  .pop-from-left {
    font-size: 3vh;
  }

  .short-description-text{
    font-size: 4vh;
  }

  .danilo-video{
    position: relative;
    float: right;
  }

  intro video{
    max-width: 250px;
    min-width: 200px;
    min-height: 150px;
  }
}

@media only screen and (max-width: 530px){
  .short-description-text {
    font-size: 3vh;
  }
  .intro {
    flex-direction: column;
    justify-content: none;
    justify-content: none;
    height: 60vh;
  }
}

/* INDEX PAGE */

/* BIO */

.bio-body {
  padding: 10%;
  font-family: 'Martel', serif;
  background-color: #FFBECB;
  background-image:
  linear-gradient(#FFBECB, #7D6B91 30%);
}

.paragraphs {
  display: flex;
  flex-direction: row;
  font-size: 2.5vh;
  color: white;
  justify-content: space-evenly;
}

.paragraph {
  min-width: 200px;
  max-width: 1500px;
  min-height: 200px;
  white-space: pre-wrap;
}

.paragraph2 {
  position: relative;
  top: 8.3vh;
}

.paragraph+.paragraph{
  margin-left: 100px
}

.pages-videos {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.pages-videos video {
  max-width: 95%;
}

.pages-videos div {
  display: flex;
  justify-content: center;
  align-self: center;
}

.video1{
  margin-left: 10%;
}

.video2 {
  margin-left: -10%;
  margin-top: 5%
}

@media only screen and (max-width: 991px){
  .bio-body h1{
    font-size: 5vh;
  }

  .paragraphs {
    font-size: 2vh;
    flex-direction: column;
  }

  .paragraph2 {
    top: 10px;
  }

  .paragraph+.paragraph{
    margin-left: 0px;
    margin-top: 30px
  }

  .pages-videos video {
    max-width: 100%;
  }

}

@media only screen and (max-width: 530px){
  .bio-body h1{
    font-size: 4vh;
  }
  
  .paragraphs {
    font-size: 16px;
    flex-direction: column;
    justify-content: none;
  }

  .paragraph {
    min-width: 100px;
    max-width: 700px;
    min-height: 50px;
  }

  .paragraph+.paragraph{
    margin-left: 0px;
    margin-top: 20px;
  }

  .pages-videos {
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
  }

  .pages-videos video{
    width: 60%;
  }

  .pages-videos div {
    display: flex;
    justify-content: center;
    align-self: center;
  }
  
  .video1{
    margin-left: -10%;
  }

  .video2 {
    margin-left: 10%;
    margin-top: -5%;
  }

}

/* SELECTED PROJECTS */

.selected-projects-body {
  background-color: #7D6B91;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 10%;
}

.selected-projects-body h1{
  margin-top: 0;
}

.projects {
  display: flex;
  flex-wrap: wrap;
    justify-content: space-between;
  font-size: 2.5vh;
  min-width: 100%;
}

.project {
  /* border: 1px solid white; */
  display: flex;
  justify-content: right;
  flex-direction: column;
  width: calc(50% - 20px);
    /* Two items per row with some spacing */
    margin-bottom: 60px;
    /* Space between rows */
}

.project-video-container {
  display: flex;
}

.project-video {
  max-width: 90%;
}

.project-text {
  max-width: 500px;
  padding-right: 10%;
}

.project+.project{
  margin-left: 0px;
}

@media only screen and (max-width: 991px){
  .project-text {
      padding-right: 0;
      text-align: center;
    }
  .selected-projects-body h1{
    font-size: 5vh;
  }

  .projects {
    font-size: 2vh;
  }

  .project-video {
    max-width: 100%;
    }
    
    .project-video-container {
      display: flex;
      justify-content: center;
  }

  .project+.project{
    margin-left: 50px;
  }

}

@media only screen and (max-width: 670px){
  .projects {
    flex-direction: column;
  }

.project {
  width: 100%;
  margin-left: 0;
  margin-top: 30px;
}

.project-video {
  max-width: 100%;
}
  .project+.project{
    margin-left: 0px;
    margin-top: 30px;
  }

}

@media only screen and (max-width: 530px){
  .selected-projects-body h1{
    font-size: 4vh;
  }

  .projects {
    font-size: 16px;
  }

}

/* SKILLS */
/* TODO cleanup these styles */

.skills-body{
  margin-top: -100px;
  padding-left: 10%;
  padding-right: 10%;
  background-image: 
  linear-gradient(#7D6B91, #5D536B 30%);
  font-size: 2.5vh;
}

.skills-body h1{
  margin-top: 0
}

.skills {
  width: 100%;
  position: relative;
  float: left;
}

.skills ul {
  list-style-type: none;
  padding-inline-start: 0;
}

ul + ul{
  margin-top: 70px;
}

.skills ul > li {
  margin-bottom: 50px;
  background: white;
  height: 6px;
}

.skills ul > li em {
  position: relative;
  top: -45px;
}

.skills ul > li span {
  height: inherit;
  background: linear-gradient(90deg, #FFC0CB, #347FC4);
  position: absolute;
}

.HTML { 
  width: 85%; 
}

.HTML-animation {
  animation: HTML-animation 2s ease-out;
}

.CSS { 
  width: 85%;  
}

.CSS-animation {
  animation: CSS-animation 2s ease-out;
}

.JavaScript {
  width: 70%;
}

.JavaScript-animation {
  animation: JavaScript-animation 2s ease-out;
}

.React {
  width: 75%;
}

.React-animation{
  animation: React-animation 2s ease-out;
}

.Java {
  width: 75%;
}

.Java-animation{
  animation: React-animation 2s ease-out;
}

.Go {
  width: 80%;
}

.Go-animation{
  animation: Go-animation 2s ease-out;
}

.SQL {
  width: 50%;
}

.SQL-animation {
  animation: SQL-animation 2s ease-out;
}

.Git {
  width: 70%;
}

.Git-animation{
  animation: Git-animation 2s ease-out;
}

.GraphQL {
  width: 40%;
}

.GraphQL-animation{
  animation: GraphQL-animation 2s ease-out;
}

.Docker {
  width: 30%;
}

.Docker-animation{
  animation: Docker-animation 2s ease-out;
}

.AWS {
  width: 30%;
}

.AWS-animation{
  animation: Docker-animation 2s ease-out;
}

@keyframes HTML-animation { 
  0%  { width:0px;} 
  100%{ width:85%;}  
}

@keyframes CSS-animation { 
  0%  { width:0px;} 
  100%{ width:75%;}  
}

@keyframes JavaScript-animation { 
  0%  { width:0px;} 
  100%{ width:65%;}  
}

@keyframes Go-animation { 
  0%  { width:0px;} 
  100%{ width:60%;}  
}

@keyframes SQL-animation { 
  0%  { width:0px;} 
  100%{ width:50%;}  
}

@keyframes WordPress-animation { 
  0%  { width:0px;} 
  100%{ width:50%;}  
}

@keyframes React-animation { 
  0%  { width:0px;} 
  100%{ width:55%;}  
}

@keyframes Liquid-animation { 
  0%  { width:0px;} 
  100%{ width:20%;}  
}

@keyframes Git-animation { 
  0%  { width:0px;} 
  100%{ width:40%;}  
}

@keyframes GraphQL-animation { 
  0%  { width:0px;} 
  100%{ width:35%;}  
}

@keyframes Docker-animation { 
  0%  { width:0px;} 
  100%{ width:30%;}  
}

@media only screen and (max-width: 991px){
  .skills-body{
    font-size: 2vh;
  }
}

@media only screen and (max-width: 530px){
  .skills-body{
    font-size: 16px;
  }
  
  .skills-body h1{
    font-size: 4vh;
  }

  ul + ul{
    margin-top: 55px;
  }

}

/* FOOTER */

.footer-body{
  display: flex;
  flex-direction: row;
  height: 20vh;
  border-top: 2px solid white;
  font-size: 3.5vh;
  color: #272838;
  justify-content: center;
  /* background-image: */
  /* linear-gradient(#5D536B, #FFBECB 90%); */
}

.footer-link{
  padding-left: 8%;
  padding-right: 8%;
  align-self: center;
  /* word-break: break-all; */
}

.footer-link a{
  background-color: transparent;
  color: #5D536B;
  text-decoration: none;
  animation: link-footer-out 0.5s forwards
}

.footer-link a:hover{
  animation: link-footer 0.5s forwards
}

.credits {
  color: #5D536B;
  font-size: 16px;
  padding-left: 10%;
  padding-bottom: 5%;
}

@keyframes link-footer {
  0% {
    color: #5D536B;
  }
  100% {
    color: black;
  }
}

@keyframes link-footer-out {
  0% {
    color: black;
  }
  100% {
    color: #5D536B;
  }
}

@media only screen and (max-width: 991px){
  .footer-body{
    font-size: 2.5vh;
  }

  .footer-link{
    padding-left: 6%;
    padding-right: 6%;
    align-self: center;
    /* word-break: break-all; */
  }
}

@media only screen and (max-width: 530px){
  .footer-body{
    font-size: 2.5vh;
  }

  .footer-body{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .footer-link{
    padding-top: 40px
  }

  .footer-link+.footer-link{
    padding-top: 40px;
  }
}
/* TODO remove temorary styles */
/* TEMPORARY STYLES */
.padding-sides-10 {
  padding-left: 10%;
  padding-right: 10%;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.text-dark {
  color: #272838;
}

/* UTOPIA CODE */
:root {
  /* FONT STEPS */
  /* @link https://utopia.fyi/type/calculator?c=320,18,1.2,1240,20,1.25,7,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
  /* Step -4: 8.6806px → 8.192px */
    --step--4: clamp(0.512rem, 0.5532rem + -0.0531vw, 0.5425rem);
    /* Step -3: 10.4167px → 10.24px */
    --step--3: clamp(0.64rem, 0.6549rem + -0.0192vw, 0.651rem);
  /* Step -2: 12.5px → 12.8px */
  --step--2: clamp(0.7813rem, 0.7747rem + 0.0326vw, 0.8rem);
    /* Step -1: 15px → 16px */
      --step--1: clamp(0.9375rem, 0.9158rem + 0.1087vw, 1rem);
  /* Step 0: 18px → 20px */
  --step-0: clamp(1.125rem, 1.0815rem + 0.2174vw, 1.25rem);
    /* Step 1: 21.6px → 25px */
      --step-1: clamp(1.35rem, 1.2761rem + 0.3696vw, 1.5625rem);
  /* Step 3: 31.104px → 39.0625px */
  --step-3: clamp(1.944rem, 1.771rem + 0.8651vw, 2.4414rem);
    /* Step 5: 44.7898px → 61.0352px */
      --step-5: clamp(2.7994rem, 2.4462rem + 1.7658vw, 3.8147rem);
      /* Step 6: 53.7477px → 76.2939px */
      --step-6: clamp(3.3592rem, 2.8691rem + 2.4507vw, 4.7684rem);
  /* Step 7: 64.4973px → 95.3674px */
  --step-7: clamp(4.0311rem, 3.36rem + 3.3555vw, 5.9605rem);
    /* Step 8: 77.3967px → 119.2093px */
      --step-8: clamp(4.8373rem, 3.9283rem + 4.5448vw, 7.4506rem);
  /* Step 10: 111.4513px → 186.2645px */
    --step-10: clamp(6.9657rem, 5.3393rem + 8.1319vw, 11.6415rem);

  /* SNOW FLAKES STEPS */
  /* @link https://utopia.fyi/type/calculator?c=320,40,1.2,1240,80,1.25,5,2,&s=0.75|0.5|0.25|0.25|0.25,1.5|2|3|4|6,s-l|3xs-3xl&g=s,l,xl,12 */

  --heavy-step-1: clamp(2.5rem, 1.6304rem + 4.3478vw, 5rem);
  --medium-step-0: clamp(2.1875rem, 1.5353rem + 3.2609vw, 4.0625rem);
  /* @link https://utopia.fyi/type/calculator?c=320,35,1.2,1240,38,1.25,5,2,&s=0.75|0.5|0.25|0.25|0.25,1.5|2|3|4|6,s-l|3xs-3xl&g=s,l,xl,12 */
  --light-step-0: clamp(2.625rem, 2.5054rem + 0.5978vw, 2.9688rem);

  /* SPERATOR STEP */
    /* @link https://utopia.fyi/type/calculator?c=320,40,1.2,1240,100,1.25,5,2,&s=0.75|0.5|0.25|0.25|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
  
    /* Step 0: 40px → 100px */
    --separator-step-0: clamp(2.5rem, 1.1957rem + 6.5217vw, 6.25rem);
  /* SPACES */
  /* @link https://utopia.fyi/space/calculator?c=320,18,1.2,1240,20,1.25,5,2,&s=0.75|0.5|0.25|0.25|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
  
  /* Space 3xs: 5px → 5px */
    --space-3xs: clamp(0.3125rem, 0.3125rem + 0vw, 0.3125rem);
    /* Space 2xs: 9px → 10px */
    --space-2xs: clamp(0.5625rem, 0.5408rem + 0.1087vw, 0.625rem);
  /* Space xs: 14px → 15px */
    --space-xs: clamp(0.875rem, 0.8533rem + 0.1087vw, 0.9375rem);
  /* Space s: 18px → 20px */
  --space-s: clamp(1.125rem, 1.0815rem + 0.2174vw, 1.25rem);
  /* Space m: 27px → 30px */
  --space-m: clamp(1.6875rem, 1.6223rem + 0.3261vw, 1.875rem);
    /* Space l: 36px → 40px */
      --space-l: clamp(2.25rem, 2.163rem + 0.4348vw, 2.5rem);

  /* One-up pairs */
    /* Space 3xs-2xs: 5px → 10px */
      --space-3xs-2xs: clamp(0.3125rem, 0.2038rem + 0.5435vw, 0.625rem);
  /* Space 4xs-3xs: 5px → 5px */
  --space-4xs-3xs: clamp(0.3125rem, 0.3125rem + 0vw, 0.3125rem);
  /* Space xs-s: 14px → 20px */
  --space-xs-s: clamp(0.875rem, 0.7446rem + 0.6522vw, 1.25rem);

  /* Custom pairs */
  
  /* Space xs-l: 14px → 40px */
    --space-xs-l: clamp(0.875rem, 0.3098rem + 2.8261vw, 2.5rem);
  /* Space s-l: 18px → 40px */
  --space-s-l: clamp(1.125rem, 0.6467rem + 2.3913vw, 2.5rem);
  
  /* Space 2xs-s: 9px → 20px */
  --space-2xs-s: clamp(0.5625rem, 0.3234rem + 1.1957vw, 1.25rem);
  --space-3xs-3xl: clamp(0.3125rem, -2.1875rem + 12.5vw, 7.5rem);
    /* Space 5xs-3xl: 5px → 120px */
      --space-5xs-3xl: clamp(0.3125rem, -2.1875rem + 12.5vw, 7.5rem);
      /* Space xl-3xl: 54px → 120px */
      --space-xl-3xl: clamp(3.375rem, 1.9402rem + 7.1739vw, 7.5rem);
      /* Space l-3xl: 36px → 120px */
      --space-l-3xl: clamp(2.25rem, 0.4239rem + 9.1304vw, 7.5rem);

  /* GRID */
  /* @link https://utopia.fyi/grid/calculator?c=320,18,1.2,1240,20,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

  --grid-max-width: 77.50rem;
  --grid-gutter: var(--space-s-l, clamp(1.125rem, 0.6467rem + 2.3913vw, 2.5rem));
  --grid-columns: 12;
/* COLORS */
  --color-border: #5C6784;
  --color-border-light: #D9E2EC;
    --color-border-dark: #4A5269;
  --color-background: #040F16;
  --color-background-elements: rgba(0, 0, 0, 0.6);
  --color-primary: white;
  --color-secondary: #D62839;
  --color-tertiary: #003D29;
    --color-quaternary: #ECA72C;
    --color-quinary: black;
    --color-secondary-dark: #9B1A2E;
    --color-tertiary-dark: #002820;
      --color-quaternary-dark: #A3622C;
}

/* HO HO HO ANIMATION */

.snow-body {
  background-color: var(--color-background);
}

.snow-container {
  max-width: 90rem;
  height: 100vh;
  padding-inline: var(--grid-gutter);
    margin-inline: auto;
  /* overflow: hidden; */
}

.snowFlakes {
  display: grid;
    width: 100%;
    position: relative;
}
  
.snowflakes-grid-base {
  /* margin-top hides start of the grids */
  margin-top: -80px;
  display: grid;
  justify-content: center;
}

.snowFlake {
  display: grid
}

.heavySnowFlakes {
  grid-template-columns: repeat(auto-fit, var(--heavy-step-1));
  gap: var(--space-xs-s);
}

.mediumSnowFlakes {
  grid-template-columns: repeat(auto-fit, var(--medium-step-0));
  gap: var(--space-xs-s);
}

.lightSnowFlakes {
  grid-template-columns: repeat(auto-fit, var(--light-step-0));
  gap: var(--space-4xs-3xs);
}

.heavySnowFlakes-container img {
  width: var(--heavy-step-1);
  height: var(--heavy-step-1);
}

.mediumSnowFlakes-container img {
  width: var(--medium-step-0);
  height: var(--medium-step-0);
}

.lightSnowFlakes-container img {
  width: var(--light-step-0);
  height: var(--light-step-0);
}

/* HO HO HO ANIMATION */
.hohoho-animation-container {
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;
  /* overflow: hidden; */
  position: relative;
}

.animation-h1 {
  font-size: var(--step-10) !important;
}
/* CHRISTMAS GIFT */

.breadcrumbs-grid {
  display: grid;
  grid-auto-flow: column;
  gap: var(--space-3xs);
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: start;
}

.breadcrumbs-grid a {
  background-color: transparent;
  color: inherit;
  text-decoration: underline;
  padding: 0;
  animation: none;
}

.color-border {
  color: var(--color-border);
}
.christmas-body h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.u-container {
  max-width: var(--grid-max-width);
  padding-inline: var(--grid-gutter);
  margin-inline: auto;
}

.u-container-form {
  max-width: 100vw;
  padding-inline: var(--grid-gutter);
  margin-inline: auto;
}
.p-grid {
display: grid;
  gap: var(--space-2xs-s);
border: 1px solid var(--color-border);
  border-radius: 10px;
  background-color: var(--color-background-elements);
  padding: var(--space-xs);
  box-shadow: 0 0 10px rgba(230, 188, 205, 0.1);
}

.p-grid--font-size {
  font-size: var(--step-0);
  font-size: var(--step-0);
  line-height: 1.8;
}
.img-grid {
  place-items: center;
  display: grid;
  padding: var(--space-xs-l);
}

.img-grid img {
  height: var(--space-l-3xl);
  background: radial-gradient(circle,
      rgba(4, 15, 22, 1) 0%,
      /* Fully opaque at the center */
      rgba(4, 15, 22, 0.8) 5%,
      /* 80% opacity at 5% distance */
      rgba(4, 15, 22, 0.5) 10%,
      /* 50% opacity at 10% distance */
      rgba(4, 15, 22, 0.2) 20%,
      /* 20% opacity at 20% distance */
      rgba(4, 15, 22, 0) 100%);
  /* Fully transparent at the edge */
  filter: drop-shadow(0 4px 15px var(--color-background-elements));
}

.navbar-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  grid-gap: var(--space-2xs-s);
}

.navbar-top {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.navbar-bottom {
  display: grid;
  justify-items: end;
}

@media (max-width: 680px) {
  .navbar-grid {
    grid-gap: var(--space-2xs-s);
  }

  .navbar-top {
    grid-template-columns: 1fr;
    grid-gap: var(--space-xs);
  }

  .navbar-bottom {
    justify-items: end;
    margin-top: var(--space-2xs-s);
  }

  .navbar-bottom .button--x-small {
    height: fit-content;
  }
}

@media (max-width: 535px) {
  .breadcrumbs-grid {
    grid-auto-flow: unset;
    grid-template-columns: 1fr;
  }

  .breadcrumbs-grid li {
    display: block;
    width: 100%;
  }
}
.text-white {
  color: var(--color-primary);
}

.text-black {
  color: var(--color-quinary) !important;
}
.text-quaternary {
  color: var(--color-quaternary);
}
.mountains-of-christmas-bold {
  font-family: "Mountains of Christmas", serif;
  font-weight: 700;
  font-style: normal;
}

.mountains-o-c-b--1st-h1 {
  margin-top: var(--space-m) !important;
}
.mountains-o-c-b--h1 {
  color: #D62839;
  font-size: var(--step-7);
  margin-bottom: var(--space-m) !important;
  margin-top: 0;
}

.mountains-o-c-b--h1-error {
  font-size: var(--step-5);
}
.quattrocento-regular {
  font-family: "Quattrocento", serif;
  font-weight: 400;
  font-style: normal;
}

.merriweather-regular {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
}

.merriweather-r--h2 {
  font-size: var(--step-3);
  margin-top: var(--space-s) !important;
  margin-bottom: var(--space-s) !important;
}
.merriweather-r--h3 {
  font-size: var(--step-2);
  color: var(--color-quaternary);
}
.hohoho-component-container {
  position: relative;
  height: 100vh;
}

/* Position Hohoho to cover the entire background */
.hohoho-component-container>.snow-body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.overlay {
  position: relative;
  z-index: 2;
  opacity: 100%;
  width: 100%;
  height: 100%;
}

/* Deadlines */
.row {
  color: white;
  text-align: center;
}

.empty-row {
  padding: var(--space-3xs-2xs)
}

.empty-row--last {
  border-bottom: 1px solid var(--color-border);
}

.empty-row--last--left-radius {
  border-bottom-left-radius: 10px;
}

.empty-row--last--right-radius {
  border-bottom-right-radius: 10px;
}

.year {
  font-size: var(--step-0);
  padding: var(--space-3xs-2xs);
}

.date {
  padding-right: var(--space-3xs-2xs);
  padding-left: var(--space-3xs-2xs);
}

/* TODO Maybe make circle as a grid? */
/* TODO combine equal properties of circle and rectangle together */

.date-circle {
  display: inline-flex;
  /* Flexbox helps to center content */
  justify-content: center;
  /* Center the number horizontally */
  align-items: center;
  /* Center the number vertically */
  width: var(--step-0);
  /* Width of the circle */
  height: var(--step-0);
  /* Height of the circle */
  border-radius: 50%;
  /* Makes the element a circle */
  color: var(--color-primary);
  /* Color of the text (number) inside the circle */
  font-size: var(--step--2);
  /* Adjust the font size to fit within the circle */
  text-align: center;
  /* Ensure the text is centered */
}

.date-rectangle {
  display: inline-flex;
  /* Flexbox helps to center content */
  justify-content: center;
  /* Center the number horizontally */
  align-items: center;
  /* Center the number vertically */
  height: var(--step-0);
  /* Height of the rectangle (same as circle for consistency) */
  border-radius: var(--step--4);
  /* Rounded corners (adjust as needed) */
  color: var(--color-primary);
  /* Color of the text (number or range) inside the rectangle */
  font-size: var(--step--2);
  /* Font size for text inside */
  text-align: center;
  /* Center the text */
  line-height: var(--step-0);
  /* Center text vertically if necessary */
  padding-left: 2px;
  padding-right: 2px;
  border: 1px solid var(--color-border);
}

.color-quaternary {
  color: var(--color-quaternary);
}

.bg-secondary {
  background-color: var(--color-secondary);
}

.border-secondary {
  border: 1px solid #E8485A;
}

.bg-tertiary {
  background-color: var(--color-tertiary);
}

.border-tertiary {
  border: 1px solid var(--color-border);
}

.bg-quaternary {
  background-color: var(--color-quaternary)
}

.border-quaternary {
  border: 1px solid #A3622C;
}

.date-number {
  color: white;
  padding-top: var(--space-3xs-2xs);
  padding-bottom: unset;
}

.date-text {
  color: white;
  font-size: var(--step--0);
}

.month {
  color: white;
  background-color: #D6283A;
  padding: var(--space-3xs-2xs);
}

.deadline-grid {
  display: grid;
  box-shadow: 0 0 10px rgba(230, 188, 205, 0.1);
  grid-template-columns: repeat(12, 1fr);
  border-radius: 10px;
}

.deadline-grid--small {
  display: grid;
  box-shadow: 0 0 10px rgba(230, 188, 205, 0.1);
  border-radius: 10px;
}

.row-date-small {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.month-dates-small {
  display: grid;
}

.row-dates-small-container {
  display: grid;
  padding: var(--space-3xs-2xs);
}

.date-day {
  grid-column: span 1;
}

.date-description {
  grid-column: span 11;
}

.font-size--step-0 {
  font-size: var(--step-0);
}

.font-size--step--1 {
  font-size: var(--step--1);
}
.border-right--dashed {
  border-right: 1px dashed var(--color-primary);
}

.border-left {
  border-left: 1px solid var(--color-border);
}

.border-right {
  border-right: 1px solid var(--color-border);
}

.border-sides {
  border-left: 1px solid var(--color-border);
  border-right: 1px solid var(--color-border);
}

.border-top {
  border-top: 1px solid var(--color-border);
}

.border-bottom {
  border-bottom: 1px solid var(--color-border);
}

.border-radius-sides-top {
  border-radius: 10px 10px 0 0;
}

.border-radius-sides-bottom {
  border-radius: 0 0 10px 10px;
}

.row-smal--text-align-left {
  text-align: left;
}

.font-size--step-0 {
  font-size: var(--step-0);
}

.margin-top--space-3xs-2xs {
  margin-top: var(--space-3xs-2xs);
}

.margin-top--space-3xs {
  margin-top: var(--space-3xs);
}

.margin-top--space-2xs {
  margin-top: var(--space-2xs);
}

.margin-top--space-s {
  margin-top: var(--space-s);
}

.margin-left--space-3xs {
  margin-left: var(--space-3xs);
}
.margin-left--space-2xs {
  margin-left: var(--space-2xs);
}
.padding--space-3xs {
  padding: var(--space-3xs);
}

.padding-left-0 {
  padding-left: 0;
}

dl {
  margin: 0;
}
.cursor-pointer {
  cursor: pointer;
}

.text-decoration--underline {
  text-decoration: underline;
}
/* Base button styles */
.button {
  padding: var(--space-xs) var(--space-s);
  font-size: var(--step-1);
  border-radius: 10px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s, transform 0.2s ease;
  display: inline-block;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Tertiary Button */
.button--tertiary {
  background-color: var(--color-secondary);
  color: white;
}

.button--tertiary:hover {
  background-color: var(--color-tertiary);
  /* Hover state: tertiary color */
  transform: translateY(-2px);
}

.button--secondary:active {
  background-color: var(--color-tertiary-dark);
  /* Active state: quaternary color */
}
/* Secondary Button */
.button--secondary {
  background-color: var(--color-tertiary);
  /* Secondary color from your theme */
  color: white;
}

.button--secondary:hover {
  background-color: var(--color-secondary);
  /* Hover state: tertiary color */
  transform: translateY(-2px);
}

.button--secondary:active {
  background-color: var(--color-secondary-dark);
  /* Active state: quaternary color */
}

.button--quaternary {
  background-color: var(--color-quaternary);
  color: var(--color-quinary);
}

.button--quaternary:hover {
  background-color: var(--color-quaternary-dark);
  color: var(--color-quinary);
}

.button--quaternary:active {
  background-color: var(--color-quaternary-dark);
}
/* Button Sizes */
.button--x-small {
  font-size: var(--step--1);
  /* Smaller text */
  padding: var(--space-2xs);
}
.button--small {
  font-size: var(--step--1);
  /* Smaller text */
  padding: var(--space-xs);
}

.button--medium {
  font-size: var(--step-1);
  /* Medium text */
  padding: var(--space-xs) var(--space-s);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Create a class that uses the animation */
.spin-animation {
  display: inline-block;
  animation: spin 0.5s linear infinite;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-out {
  display: inline-block;
  animation: fadeOut 0.3s forwards;
  /* 1s duration, 'forwards' keeps the final state (opacity: 0) */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in {
  display: inline-block;
  animation: fadeIn 0.3s ease-in-out forwards;
}
/* Disabled State */
.button--disabled {
  background-color: var(--color-border-light);
    color: var(--color-border-dark);
  cursor: not-allowed;
  box-shadow: none;
}

.button--disabled:hover {
  background-color: var(--color-border-dark);
  color: white;
}
.two-buttons-container {
  display: grid;
  grid-template-columns: repeat(2, calc(var(--step-1) * 6.5));
  gap: var(--space-xs-l);
}

@media (max-width: 380px) {
  .two-buttons-container {
    grid-template-columns: 1fr;
    gap: var(--space-s);
  }
}
/* Basic footer styles */
.footer {
  margin-top: var(--space-l);
  padding: var(--space-2xs);
  padding-bottom: var(--space-2xs);
  background-color: var(--color-background);
}

.footer-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: var(--space-3xs);
}

.footer-text {
  align-self: end;
  text-align: right;
  color: var(--color-text-secondary);
  margin: 0;
}

.footer-image {
  height: var(--step-7);
}

.input-modern {
  border: 2px solid #333;
  background-color: #111;
  color: #fff;
  border-radius: 4px;
  padding: var(--space-3xs);
  font-size: var(--step-0);
  width: -webkit-fill-available;
  transition: background-color 0.3s, color 0.3s;
}

.input-modern:focus {
  background-color: #222;
  color: var(--color-quaternary);
  outline: none;
}

div .input-modern {
  margin-bottom: var(--space-3xs);
}

.u-container div label {
  font-family: "Merriweather", serif;
  font-weight: 400;
  font-style: normal;
}

.u-container div input {
  font-family: "Quattrocento", serif;
  font-weight: 400;
  font-style: normal;
}

.u-container div textarea {
  font-family: "Quattrocento", serif;
  font-weight: 400;
  font-style: normal;
}

.input-paragraph--small {
  line-height: 1.2;
  color: var(--color-border);
}

.input--span {
  font-size: var(--step--2);
  margin-left: var(--space-2xs);
}

textarea {
  resize: none;
}

.char-counter {
  margin-top: -18px;
  text-align: right;
  font-size: var(--step--2);
  color: #555;
}

.display-none {
  display: none !important;
}

.error-message-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.error-message-content {
  border: 1px solid var(--color-secondary);
  background: #111;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: var(--step-0);
  /* color: #333; */
}

#status-message {
  border: 1px solid var(--color-quaternary);
  background: #111;
  padding: var(--space-3xs);
  /* Adjusted padding for a smaller element */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: var(--step-0);
  color: #fff;
  margin: var(--space-s) 0 auto;
}

.input-error {
  color: var(--color-secondary);
  font-size: var(--step--1);
  margin-top: var(--space-3xs);
}
.dd-link {
  background-color: unset !important;
  animation: unset !important;
  color: var(--color-quaternary) !important;
  text-decoration: underline !important;
  padding: 0 !important;
}
.loading-snow-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1000;
}

.loading-snowflake {
  position: absolute;
  width: 100px;
  height: 100px;
}
/* TOP BAR */
.text-align--center {
  text-align: center;
}